<template>
  <div>
    <v-btn color="primary lighten-3"  @click="dialog=true" class="ml-3" small>
      <v-icon class="pr-2">mdi-book-plus-multiple</v-icon>NUEVA FORMACIÓN
    </v-btn>

    <v-dialog persistent v-model="dialog" max-width="700px" transition="dialog-transition" scrollable>
      <v-card>
        <v-toolbar color="primary" class="mb-2">
          <span class="subtitle white--text">{{titulo}}</span>
        </v-toolbar>
        <v-card-text class="pt-5">
          <v-form ref="formNuevaFormacion" v-model="valid" lazy-validation>
            <v-layout row wrap>
              <v-flex lg12 md12>
                 <v-autocomplete
                  v-model="formacion.universidad"
                  label="Institucion"
                  :items="universidades"
                  :rules="rules.required"
                  class="required px-1 pt-2"
                  :disabled="!edit"
                  clearable
                  return-object
                  item-text="nombre"
                  item-value="id"
                  @change="updateUniversidad"
                ></v-autocomplete>
              </v-flex>
              <v-flex lg4 md4>
                <v-select
                  v-model="formacion.tipoInstitucion"
                  label="Tipo de insitución"
                  :items="$store.state.session.parametros.tipo_institucion"
                  :rules="rules.required"
                  class="required px-1"
                  :disabled="!edit"
                  clearable
                ></v-select>
              </v-flex>
              <v-flex lg4 md4>
                <v-select
                  v-model="formacion.gradoFormacion"
                  label="Grado"
                  :items="$store.state.session.parametros.grado_formacion"
                  :rules="rules.required"
                  class="required px-1"
                  :disabled="!edit"
                  clearable
                  return-object
                  @change="updateGrado"
                ></v-select>
              </v-flex>
              <v-flex lg4 md4>
                <v-select
                  v-model="formacion.areaFormacion"
                  label="Área"
                  :items="conDatos?areas:$store.state.session.parametros.area_formacion"
                  :rules="rules.required"
                  class="required px-1"
                  :disabled="!edit"
                  clearable
                  return-object
                  @change="updateArea"
                ></v-select>
              </v-flex>
              <v-flex lg12 md12 v-if="!selectIdioma">
                <v-text-field
                  v-model="formacion.nombre"
                  label="Descripción"
                  :disabled="!edit"
                  :rules="rules.required"
                  class="required px-1"
                ></v-text-field>
              </v-flex>
              <v-flex lg12 md12 v-else>
                  <v-select
                  v-model="formacion.nombre"
                  label="Descripción"
                  :items="idiomas"
                  :rules="rules.required"
                  class="required px-1"
                  :disabled="!edit"
                  clearable
                  item-text="text"
                  item-value="text"
                ></v-select>
              </v-flex>
             
              <v-flex lg4 md4>
                <v-text-field
                  v-model="formacion.numeroDocumento"
                  label="Número de documento"
                  :disabled="!edit"
                  :rules="rules.required"
                  class="required px-1"
                ></v-text-field>
              </v-flex>
              <v-flex lg4 md4>
                <DateField
                  :date="formacion.fechaFin"
                  propiedad="fechaFin"
                  :birthDay="true"
                  label="Fecha de expedición"
                  :rules="rules.required"
                  :edit="!edit"
                  classField="required"
                  @cambiarProp="cambiarProp"
                />
              </v-flex>
              <v-flex lg4 md4 v-if="selectCarga && selectIdioma">
                <v-text-field
                  v-model="formacion.cargaHoraria"
                  label="Cantidad de hrs. académicas"
                  :disabled="!edit"
                  :rules="rules.required"
                  type="number"
                  class="required px-1"
                ></v-text-field>
              </v-flex>

              <v-flex lg12 m12 v-if="documentoObligatorio">
                <v-alert
                  :value="true"
                  type="info"
                  icon="mdi-information"
                  outlined
                >
                  Ajunta tu certificado/titulo escaneado (ambas caras) en un solo archivo en formato PDF
                </v-alert>
              </v-flex>
              <v-flex lg12 m12 v-if="!documentoObligatorio">
                <v-alert
                  :value="true"
                  type="warning"
                  icon="mdi-information"
                  outlined
                >
                  Al momento de guardar, se verificara la autenticidad de los datos mediante INTEROPERABILIDAD
                </v-alert>
              </v-flex>
              <v-flex lg12 m12 v-if="documentoObligatorio">
                <v-text-field
                  label="Adjunto"
                  class="required"
                  @click="pickFile"
                  v-model="fileName"
                  prepend-icon="mdi-paperclip"
                  :readonly="true"
                  :disabled="fileUrl!=''"
                  :rules="rules.required"
                >
                <template slot="append-outer"  v-if="fileUrl!=''">
                  <v-btn small color="error" dark @click="fileUrl=''" icon>
                    <v-icon small>mdi-delete</v-icon>
                  </v-btn>
                </template>
                </v-text-field>
                <input
                  type="file"
                  style="display: none"
                  ref="fileUpload"
                  accept="application/pdf"
                  @change="onFilePicked"
                />
              </v-flex>
            </v-layout>
            <!-- <Form :modelo="formacion" :fields="fieldsFormacion" :edit="edit" :create="true" /> -->
          </v-form>
          <div id="previewPDF" ></div> 
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="cancelarRegistro">cancelar</v-btn>
          <v-btn color="primary" @click="registrarFormacion" :loading="loading" :disabled="!valid">GUARDAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import DateField from "@/common/util/crud/DateField";
import PDFObject from "@/../paquetes_externos/pdfobject.min";


export default {
  props:{
    areas:{
      type:Array,
      default:()=>{
        return []
      }
    },
    conDatos:{
      type:Boolean,
      default:false
    },
    urlAdd:{
      type: String,
      default: 'abogado-ciudadano/formacion'
    },
    globalAdd:{
      type: Boolean,
      default: true
    }
  },
  components: { DateField },
  data() {
    return {
      titulo: "Registrar nueva formacion académica",
      valid: true,
      edit: true,
      dialog: false,
      formacion: {
        gradoFormacion: null,
        areaFormacion: null,
        institucion: null,
        tipoInstitucion: null,
        nombre: null,
        fechaInicio: null,
        fechaFin: null
      },
      fieldsFormacion: [],
      loading:false,
      universidades:[],
      idiomas:[],
      selectIdioma:false,
      selectCarga:false,
      documentoObligatorio:true,
      documento:null,
      fileName:'',
      fileUrl:'',
      rules:{
        required:[v=>!!v||'Este campo es obligatorio']
      }
    };
  },
  async mounted() {
    // this.fieldsFormacion = [
    //   {
    //     label: "Grado Formacion",
    //     model: "gradoFormacion",
    //     rules: "requiredSelect",
    //     classField: "required px-1 pt-2",
    //     type: "autocomplete",
    //     items: this.$store.state.session.parametros.grado_formacion,
    //     class: "lg6 md6"
    //   },
    //   {
    //     label: "Area Formacion",
    //     model: "areaFormacion",
    //     type: "autocomplete",
    //     class: "lg6 md6",
    //     rules: "requiredSelect",
    //     classField: "required px-1 pt-2",
    //     items: this.conDatos?this.areas:this.$store.state.session.parametros.area_formacion
    //   },
    //   {
    //     label: "Tipo Institucion",
    //     model: "tipoInstitucion",
    //     type: "select",
    //     class: "lg4 md4",
    //     rules: "requiredSelect",
    //     classField: "required px-1 pt-4",
    //     items: this.$store.state.session.parametros.tipo_institucion
    //   },
    //   {
    //     label: "Nombre Institucion",
    //     model: "institucion",
    //     type: "text",
    //     rules: "required",
    //     classField: "required",
    //     class: "lg8 md8",
    //     classField: "required px-1"
    //   },

    //   {
    //     label: "Nombre Formacion",
    //     model: "nombre",
    //     type: "text",
    //     rules: "required",
    //     classField: "required",
    //     class: "lg12 md12",
    //     classField: "required px-1 pt-2"
    //   },
    //   {
    //     label: "Fecha Inicio",
    //     model: "fechaInicio",
    //     type: "date",
    //     birthday: true,
    //     class: "lg4"
    //   },
    //   {
    //     label: "Fecha Fin",
    //     model: "fechaFin",
    //     type: "date",
    //     birthday: true,
    //     class: "lg4"
    //   }
    // ];
    const resp = await this.getUrlItems(`${this.$apiUrl}abogado-ciudadano/universidades?limit=1000`)
    this.universidades = resp.map(e=>({...e,nombre:`${e.nombreUniversidad} - ${e.sigla}`}))
    this.idiomas = await this.getUrlItems(`${this.$apiUrl}abogado-ciudadano/parametros?grupo=IDIOMA&limit=200`)
  },
  methods: {
    async getUrlItems(url){
       const respuesta = await this.$http.get(url)
       return respuesta.data.data.items || respuesta.data.data
    },
    updateUniversidad(){
      this.documento=null
      this.fileUrl=""
      this.fileName=""
      var element = document.getElementById("previewPDF");
      element.innerHTML = '';
      if(this.formacion.universidad){
        this.formacion.tipoInstitucion = this.formacion.universidad.tipoInstitucion
        if(this.formacion.universidad.iopCertificacionTitulo){
          this.documentoObligatorio=false
        }
      }
    },
    updateGrado(){
      this.formacion.areaFormacion = null
      this.formacion.nombre = null
      if(this.formacion.gradoFormacion){
        this.selectCarga = this.formacion.gradoFormacion.codigo === 'GF_CURSO'
      }
    },
    updateArea(){
      this.formacion.nombre = null
      if(this.formacion.areaFormacion){
        this.selectIdioma = this.formacion.areaFormacion.codigo === 'AF-IDIOMA'
      }
    },
    registrarFormacion() {
      if (this.$refs.formNuevaFormacion.validate()) {
        const data = {
              institucion: this.formacion.universidad.text || this.formacion.universidad.nombre,
              tipoInstitucion: this.formacion.tipoInstitucion || null,
              universidad: this.formacion.universidad.value || this.formacion.universidad.id || null,
              gradoFormacion: this.formacion.gradoFormacion.value || this.formacion.gradoFormacion.id || null,
              areaFormacion: this.formacion.areaFormacion.value || this.formacion.areaFormacion.id || null,
              nombre: this.formacion.nombre || null,
              numeroDocumento: this.formacion.numeroDocumento || null,
              fechaFin: this.formacion.fechaFin || null,
              cargaHoraria: this.formacion.cargaHoraria || null
            }
        this.loading=true
        let formData = new FormData();
            if(!this.formacion.universidad.iopCertificacionTitulo){
              formData.append("documento", this.documento);
            }
            formData.append("formacion",JSON.stringify(data))
        this.$http
          .post(`${this.$apiUrl}${this.urlAdd}`, formData, {
             headers: {"Content-Type": "multipart/form-data"}
          })
          .then(response => {
            this.loading=false
            if(this.globalAdd){
                this.$store.commit('abogado/setFormacion',response.data.data);
                this.$emit('creado',response.data.data[0])
            }else{
                this.$emit('creado',response.data.data)
            }
            this.cancelarRegistro();
          }).catch(error=>{
            console.log(error)
            this.loading=false
          });
      }
    },
    cancelarRegistro() {
      this.$refs.formNuevaFormacion.reset();
      this.formacion.fechaInicio=null
      this.formacion.fechaFin=null
      this.dialog = false;
    },
    cambiarProp(val) {
      this.formacion[val.campo] = val.valor;
    },
    pickFile() {
      this.$refs.fileUpload.click();
    },
     onFilePicked(e){
        try{
          const files = e.target.files
          if (files[0] !== undefined) {
              this.fileName = files[0].name
              if (this.fileName.lastIndexOf(".") <= 0) {
                  return
              }
              this.documento = files[0]
              this.fileUrl = URL.createObjectURL(files[0]);
              setTimeout(() => {this.showDocumento(this.fileUrl);}, 100)
          }else{
            this.fileName = "";
            this.documento=null
            this.fileUrl = "";
          }
        }catch(e){
          console.log(e);
        }
      },
      showDocumento(pdf) {
        this.dialog = true;
        const options = {
          height: "300px",
          pdfOpenParams: {
            pagemode: "thumbs",
            navpanes: 0,
            toolbar: 1,
            statusbar: 1,
            view: "FitH"
          }
        };
        PDFObject.embed(pdf, "#previewPDF", options);
      }
  }
};
</script>

